/* ========================================================================
/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {

        // Tooltip fn
        $(function () {
          $('[data-toggle="tooltip"]').tooltip();
        });
        // JavaScript to be fired on all pages

        // Hides Subnav when nav is expanded
        $('.navbar-toggle').on('click', function () {
          var cls = $(this).hasClass('collapsed');

          if (cls) {
            $('.subnav').hide();
          } else {
            $('.subnav').show();
          }

        });

        // Isotope
        $(window).load(function () {
          $('.grid').isotope({
            // options
            itemSelector: '.grid-item',
            layoutMode: 'fitRows',
            fitRows: {
              columnWidth: '.col-md-3'
            }
          });

          // Isotope filtering

        });

        // Format Phone numbers
        var phone = document.getElementsByClassName('phone');

        for (var i = 0; i < phone.length; i++) {
          var numbers = phone[i].innerHTML;
          var first = numbers.slice(0,3);
          var second = numbers.slice(3,6);
          var third = numbers.slice(6,10);

          newNum = "(" + first + ")" + " " + second + " " + "-" + " " + third;

          phone[i].innerHTML = newNum;
        }


        //Smooth scroll
          $("a[href^='#']").on('click', function(e) {

              // prevent default anchor click behavior
              e.preventDefault();

              // store hash
              var hash = this.hash;

              // animate
              $('html, body').animate({
                  scrollTop: $(hash).offset().top
              }, 1000, 'easeOutCirc');

          });


          // Page transitions
          /*
          if ( $( 'section' ).hasClass( 'fadeIn') ) {
            $('nav a, .link-a').on( 'click', function(){
              $('section').addClass('fadeOut');
            });
          }
          if ( $( 'section' ).hasClass( 'fadeInUp') ) {
            $('nav a, .link-a').on( 'click', function(){
              $('section').addClass('fadeOutDown');
            });
          }
          if ( $( 'section' ).hasClass( 'home-animate') ) {
            $('nav a, .link-a').on( 'click', function(){
              $('.home-animate').addClass('animated fadeOut');
            });
          }
          */

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page

        // MAKE ELEMENTS KEYBOARD ACCESSIBLE
        jQuery( document ).on( "keypress", ".keyboard-toggle", function(e) {
          if (e.which == 13) 
            { 
            jQuery( this ).click();
            }
        });


        // Subnav Control
        $(window).scroll(function () {
          var scrollPos  = $(window).scrollTop();
          var about      = $('#about').offset().top;
          var leadership = $('#leadership').offset().top;
          var services   = $('#services').offset().top;
          var partner    = $('#partner').offset().top;
          var contact    = $('#contact').offset().top;

          // Sticky Nav 
          /*
          if (scrollPos >= about) {
            $('nav.navbar').addClass('nav-freeze');
            $('.subnav').addClass('sub-freeze');
          } else {
            $('nav.navbar').removeClass('nav-freeze');
            $('.subnav').removeClass('sub-freeze');
          }
          */

          // Scrollspy
          /*
          if (scrollPos > about - 300 && scrollPos < leadership - 300) {
            $('.subnav li').removeClass('active');
            $('li.about').addClass('active');
            console.log('about');
          } else if (scrollPos > leadership - 300 && scrollPos < services - 300) {
            $('.subnav li').removeClass('active');
            $('li.leadership').addClass('active');
          } else if (scrollPos > services - 300 && scrollPos < partner - 300) {
            $('.subnav li').removeClass('active');
            $('li.services').addClass('active');
          } else if (scrollPos > partner - 300 && scrollPos < contact - 300) {
            $('.subnav li').removeClass('active');
            $('li.partner').addClass('active');
          } else if (scrollPos > contact - 300 ) {
            $('.subnav li').removeClass('active');
            $('li.contact').addClass('active');
          }
          */

          // Sticky contactBox
          if (scrollPos >= contact ) {
            $('.contact-box').addClass('active-box');
          } else {
            $('.contact-box').removeClass('active-box');
          }
        });
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // Case studies page
    'casestudies': {
      init: function() {
        var region = document.getElementById('region');
        var $grid  = $('.grid');
        // JavaScript to be fired on the home page
        //$('nav.navbar').addClass('nav-freeze');

        // Adds delayed gallery reveal
        $(window).on('load', function () {
          var $items = $grid.find('.grid-item');

          $('.grid').addClass('is-showing-items')
          .isotope( 'revealItemElements', $items );
        });

        $('footer').on('click', function () {
          console.log('test');
        });



        // Isoptope filtering
        $('select').on('change', function () {
          var selectId = this.id;
          var value = document.getElementById( selectId ).value;
          console.log(value);
          $grid.isotope({ filter: '.' + value });
        });

        // Resets filter
        $('select').on('click', function () {
          var select = document.querySelectorAll('select');

          $('option').prop('selected', function() {
              return this.defaultSelected;
          });
        });

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // News page
    'news': {
      init: function() {
        // JavaScript to be fired on the home page
        //$('nav.navbar').addClass('nav-freeze');
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // single page
    'single': {
      init: function() {


        // Sets first gallery image to active and building carousel indicators
        var item = document.getElementsByClassName('item');
        var dot = document.getElementsByClassName('indicator');

        item[0].className = 'item active';
        dot[0].className = 'indicator active';

        for (var i = 0; i < dot.length; i++) {
          dot[i].setAttribute("data-slide-to", i);
        }



        // JavaScript to be fired on the home page
        //$('nav.navbar').addClass('nav-freeze');
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
